<template>
  <section class="section-title">
    <div class="section-title__icon">
      <NuxtImg
        v-if="image"
        provider="localProvider"
        :src="image"
        :width="changeXL(64, 40)"
        :height="changeXL(64, 40)"
        loading="lazy"
      />
    </div>
    <div class="section-title__text">{{ text }}</div>
  </section>
</template>

<script setup lang="ts">
import type { ISectionTitleProps } from './SectionTitle.types';
import type { TBackgroundColor } from '~/types/Shared.types';
import { Colors } from '~/constants/colors.constants';

const props = defineProps<ISectionTitleProps>();

const changeXL = GlobalUtils.Media.changeByMedia('xl');

const styleVariables = computed(() => {
  const lineColor: TBackgroundColor = props.lineColor
    ? props.lineColor
    : {
        colorStops: [Colors.MAIN.PURPLE_100, Colors.ADDITIONAL.TRANSPARENT],
        toDirection: '270deg',
      };

  const textColor: TBackgroundColor = props.textColor
    ? props.textColor
    : {
        colorStops: [Colors.NEUTRAL.WHITE, Colors.MAIN.PURPLE_100],
        toDirection: '180deg',
      };

  return {
    lineColor: GlobalUtils.CSS.getBackgroundColor(lineColor),
    textColor: GlobalUtils.CSS.getBackgroundColor(textColor),
  };
});
</script>

<style scoped lang="scss">
.section-title {
  --line-color: v-bind('styleVariables.lineColor');
  --text-color: v-bind('styleVariables.textColor');
}
</style>

<style scoped lang="scss" src="./SectionTitle.scss" />
